import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = { isSubmenuOpen: { type: Boolean, default: false } };
    static targets = [
        "menu",
        "icon",
        "logo",
        "arrow",
        "howItWorks",
        "howItWorksVideo",
        "destinations",
        "focusAnimals",
        "navProjectFocus",
        "navAnimalsWildlife",
        "userMenu",
        "profile",
        "avatar",
        "hamburger",
    ];

    onTurboBeforeVisit(callback) {
        document.addEventListener("turbo:before-visit", callback, { once: true, capture: true, passive: true });
    }

    removeTurboBeforeVisit(callback) {
        document.removeEventListener("turbo:before-visit", callback, { once: true, capture: true, passive: true });
    }

    preventScrollingOfElementsBelowMenu() {
        document.documentElement.classList.add("html--overflow-hidden");
        document.body.classList.add("body--overflow-hidden");
    }

    allowScrollingAgain() {
        document.documentElement.classList.remove("html--overflow-hidden");
        document.body.classList.remove("body--overflow-hidden");
    }

    showFullscreen(target) {
        target.classList.remove("invisible");
        target.classList.remove("opacity-0");
        target.classList.remove("pointer-events-none");

        this.element.classList.remove("z-10");
        this.element.classList.add("z-30");
    }

    hideFullscreen(target) {
        target.classList.add("opacity-0");
        target.classList.add("pointer-events-none");

        this.element.classList.remove("z-30");
        this.element.classList.add("z-10");
    }

    openFullscreenMenu(target, close) {
        this.closeCallback = close.bind(this);
        // When hotwired turbo is enabled and works, a click on a nav link will fetch the new page and replace elements inside body and head but not update the classes for body nor html element.
        // When a fullscreen menu is opened, classes are added to html and body to prevent accidental scrolling of the body below.
        // To remove the classes before loading the selected page the turbo:before-visit events is used.
        // Otherwise the loaded page can not be scrolled since the classes prevent that.
        this.onTurboBeforeVisit(this.closeCallback);
        this.isSubmenuOpenValue = true;
        this.preventScrollingOfElementsBelowMenu();
        this.showFullscreen(target);
    }

    closeFullscreenMenu(target) {
        this.removeTurboBeforeVisit(this.closeCallback);
        this.isSubmenuOpenValue = false;
        this.allowScrollingAgain();
        this.hideFullscreen(target);
    }

    openMenu() {
        this.menuTarget.classList.remove("invisible");
        this.menuTarget.classList.remove("scale-0");
        this.menuTarget.classList.remove("-translate-x-[50%]");
        this.menuTarget.classList.remove("-translate-y-[50%]");
        this.menuTarget.classList.remove("opacity-0");

        this.element.classList.remove("z-10");
        this.element.classList.add("z-30");

        document.body.addEventListener("click", this.closeMenu.bind(this), {
            capture: true,
            passive: true,
            once: true,
        });
    }

    closeMenu() {
        this.menuTarget.classList.add("scale-0");
        this.menuTarget.classList.add("-translate-x-[50%]");
        this.menuTarget.classList.add("-translate-y-[50%]");
        this.menuTarget.classList.add("opacity-0");

        if (!this.isSubmenuOpenValue) {
            this.element.classList.remove("z-30");
            this.element.classList.add("z-10");
        }
    }

    openHowItWorks() {
        this.openFullscreenMenu(this.howItWorksTarget, this.closeHowItWorks);
        this.dispatch("how-it-works-open");
    }

    closeHowItWorks() {
        this.closeFullscreenMenu(this.howItWorksTarget);
        const videoController = this.application.getControllerForElementAndIdentifier(
            this.howItWorksVideoTarget,
            "youtube-video"
        );
        videoController.stopVideo();
        this.dispatch("how-it-works-closed");
    }

    openDestinations() {
        this.openFullscreenMenu(this.destinationsTarget, this.closeDestinations);
    }

    closeDestinations() {
        this.closeFullscreenMenu(this.destinationsTarget);
    }

    openFocusAnimals(event) {
        this.openFullscreenMenu(this.focusAnimalsTarget, this.closeFocusAnimals);

        const focusAnimalsController = this.application.getControllerForElementAndIdentifier(
            this.focusAnimalsTarget,
            "focus-animals"
        );

        if (this.navProjectFocusTargets.includes(event.target)) {
            focusAnimalsController.changeTabToProjectFocus();
        } else if (this.navAnimalsWildlifeTargets.includes(event.target)) {
            focusAnimalsController.changeTabToAnimalsWildlife();
        }
    }

    closeFocusAnimals() {
        this.closeFullscreenMenu(this.focusAnimalsTarget);
    }

    openUserMenu() {
        this.isSubmenuOpenValue = true;
        this.userMenuTarget.classList.remove("invisible");
        this.userMenuTarget.classList.remove("scale-0");
        this.userMenuTarget.classList.remove("translate-x-[50%]");
        this.userMenuTarget.classList.remove("-translate-y-[50%]");
        this.userMenuTarget.classList.remove("opacity-0");

        this.element.classList.remove("z-10");
        this.element.classList.add("z-30");

        document.body.addEventListener("click", this.closeUserMenu.bind(this), {
            passive: true,
            once: true,
            capture: true,
        });
    }

    closeUserMenu() {
        this.userMenuTarget.classList.add("scale-0");
        this.userMenuTarget.classList.add("translate-x-[50%]");
        this.userMenuTarget.classList.add("-translate-y-[50%]");
        this.userMenuTarget.classList.add("opacity-0");

        this.element.classList.remove("z-30");
        this.element.classList.add("z-10");
    }
}
